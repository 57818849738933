import React from 'react'
import Layout from '../components/Layout'
import '../styles/styles.scss'

const privacy = () => {
  return (
    <Layout>
      <div className="privacy-article">
        <h1>Privacy Policy</h1>
        <h3>What Information Is Being Collected? </h3>
        <p>
          Only your email address. If you decide to subscribe to our news
          letter.
        </p>
        <h3>Who is collecting it?</h3>
        <p>I am, the author Richard Weale. My business address is, </p>
        <p>
          FGO Publications @ FG Ottico Ltd Aldreth Pearcroft Road Stonehouse
          Gloucestershire GL10 2JY United Kingdom
        </p>
        <h3>Why is it being collected and how will it be used?</h3>
        <p>
          This is a spam free zone. I take your privacy seriously and will only
          use your personal information to email you to let you know about a new
          book release, communicating regarding my Reader Group or Launch Team,
          or if I’m running a discount or promotion that I think you’ll like, or
          if I’m giving away cool stuff like signed copies of my books, free
          kindle e-readers, tablets, or other top notch goodies.
        </p>
        <p>
          I don’t want to overload your inbox – so you’ll only hear from me a
          couple of times per month, at the most. If you are part of the Launch
          Team there will be around five emails over a two week period coming up
          to launch day.
        </p>
        <h3>How is it collected?</h3>
        <p>
          {' '}
          You click on a link on my website, richardweale.com which takes you
          through a two step process. You will have the opportunity to choose to
          opt in to joining my Reader’s Club or Launch Team, giving your consent
          as you do so. To manage my email communications in a professional
          manner I use a service called MailChimp. MailChimp organises the
          collection of your email address, via a sign-up form, and storage of
          your email address within my MailChimp account in order to allow me to
          send you email communications. Check out their privacy policy at the
          following link.
          <a href=" https://mailchimp.com/legal/privacy/" target="_blank">
            Mailchimp Privacy Policy
          </a>
        </p>
        <h3>Who will it be shared with?</h3>
        <p>NO ONE.</p>
        <h3>Amazon Associates</h3>
        <p>
          Richard Weale is a participant in the Amazon Services Associates
          Program, an affiliate advertising program designed to provide a means
          for sites to earn advertising fees by advertising to amazon.com etc.
        </p>
        <p>
          <strong>11th August 2020</strong>
        </p>
      </div>
    </Layout>
  )
}

export default privacy
